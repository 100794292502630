<template>
  <div class="pb-5">
    <p>
      Please confirm that you have completed each step by clicking the button
      below.
    </p>
    <button
      @click="nextStep()"
      class="btn btn-primary mt-3"
      :disabled="loading"
    >
      <span v-if="!loading">Confirm</span>
      <span v-else
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "StepSeven",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async nextStep() {
      this.loading = true;

      // Define the params object with auth: true
      const params = { auth: true };

      // Send the JSON data to the server
      this.$axios
        .post(
          this.$store.state.root_url + "/step/finish",
          {
            step: {
              title: this.$route.meta.title,
              number: this.$route.meta.step,
              category: this.$route.meta.category,
            },
            user: { first_name: "", last_name: "", user_id: "" },
            vehicle: {
              vin: this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
                ? this.$store.state.user.reservations.find((r) => {
                    return (
                      r.platform_id ==
                      this.$route.params.reservation_id.toUpperCase()
                    );
                  }).vehicle_vin
                : null,
            },
            reservation: {
              platform_id: this.$route.params.reservation_id.toUpperCase(),
            },
          },
          {
            params,
          }
        )
        .then((response) => {
          // Handle the response from the server
          if (response.status === 200) {
            this.loading = false;
            if (this.$route.meta.category == "Return" && this.findNext) {
              this.$router.push(
                "/next/" + this.findNext.platform_id.toLowerCase()
              );
            } else {
              this.$router.push("/schedule");
            }
          } else {
            this.loading = false;
            alert(
              "Failed to upload images. Please try again or contact support if the error persists."
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error uploading images:", error);
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        });
    },
  },
  computed: {
    findNext: function () {
      const reservations = this.$store.state.user.reservations;

      // Get the specific reservation from the route params
      const reso = reservations.find(
        (reservation) =>
          reservation.platform_id ===
          this.$route.params.reservation_id.toUpperCase()
      );

      // Ensure we have a valid reservation to work with
      if (!reso) {
        console.error("Reservation not found.");
        return null;
      }

      // Today's date in the vehicle's timezone
      const today = new Date().toLocaleDateString("en-US", {
        timeZone: reso.return_timezone,
      });

      // Function to check if a date is today
      const isToday = (dateString, timezone) => {
        const date = new Date(dateString).toLocaleDateString("en-US", {
          timeZone: timezone,
        });
        return date === today;
      };

      // Filter reservations by those having the same VIN and either pickup_at or return_at is today
      const vehicleReservations = reservations.filter(
        (reservation) =>
          reservation.platform_id !== reso.platform_id &&
          reservation.vehicle_vin === reso.vehicle_vin &&
          (isToday(reservation.pickup_at, reservation.return_timezone) ||
            isToday(reservation.return_at, reservation.return_timezone))
      );

      // Sort the filtered reservations from earliest to latest based on pickup_at
      const sortedReservations = vehicleReservations.sort((a, b) => {
        const aDate = new Date(a.pickup_at);
        const bDate = new Date(b.pickup_at);
        return aDate - bDate;
      });

      return sortedReservations.length > 0 &&
        isToday(
          sortedReservations[0].pickup_at,
          sortedReservations[0].pickup_timezone
        )
        ? sortedReservations[0]
        : null;
    },
  },
};
</script>
