<template>
  <div class="fluid-container">
    <div class="row g-0" style="min-height: 65vh !important">
      <div class="col-10 offset-1">
        <div class="mt-5 d-flex justify-content-between">
          <router-link to="/schedule" tag="a" class="my-auto"
            >← Back</router-link
          >
        </div>
        <div class="progress mt-3 mb-3">
          <div
            class="progress-bar"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: w + '%' }"
          ></div>
        </div>
        <h1 class="mt-3 mb-3">
          <span class="highlighted">
            {{ this.$route.meta.title }}
          </span>
        </h1>
        <p>
          You have selected reservation
          {{ this.$route.params.reservation_id.toUpperCase() }} for vehicle
          {{
            $store.state.user.reservations.find((reservation) => {
              return (
                reservation.platform_id ==
                this.$route.params.reservation_id.toUpperCase()
              );
            }).vehicle_vin
          }}
          ({{
            $store.state.user.reservations
              .find((reservation) => {
                return (
                  reservation.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
              .vehicle_vin.slice(-6)
          }})
        </p>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnView",
  data: function () {
    return {
      w: 0,
    };
  },
  methods: {
    getProgressBarWidth() {
      const url = window.location.href;
      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split("/");
      this.w = Math.floor(
        (100 * (parseInt(pathSegments[pathSegments.length - 1]) || 0)) / 5
      ).toString();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.getProgressBarWidth();
    next();
  },
  created() {
    this.getProgressBarWidth();
  },
  updated() {
    this.getProgressBarWidth();
  },
};
</script>

<style>
video {
  object-fit: fill;
}
</style>
