import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueFormulate from "@braid/vue-formulate";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import axios from "axios";
import "./middleware";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueFormulate, {
  classes: {
    outer: "",
    input: "form-control",
    inputHasErrors: "is-invalid",
    help: "form-text text-muted",
    errors: "list-unstyled text-danger",
  },
});

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
