<template>
  <div class="pb-5">
    <div class="row g-0">
      <div class="col-12 position-relative">
        <!-- Loading spinner overlay -->
        <div
          v-if="loading && cameraStream"
          class="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style="background-color: white !important"
        >
          <div class="spinner-border text-primary highlighted" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!-- Tread Depth Input -->
        <div
          class="mb-3"
          v-if="currentImage.inputs.includes('tread_depth') && cameraStream"
        >
          <label class="form-label">Enter Measurement:</label>
          <select class="form-select" id="tread_depth" v-model="tread_depth">
            <option value="1/32">1/32</option>
            <option value="2/32">2/32</option>
            <option value="3/32">3/32</option>
            <option value="4/32">4/32</option>
            <option value="5/32">5/32</option>
            <option value="6/32">6/32</option>
            <option value="7/32">7/32</option>
            <option value="8/32">8/32</option>
            <option value="9/32">9/32</option>
            <option value="10/32">10/32</option>
          </select>
        </div>

        <!-- Video feed -->
        <video
          ref="cameraFeed"
          width="100%"
          height="auto"
          style="min-width: 100% !important"
          autoplay
          :class="{ 'd-none': !cameraStream }"
          playsinline
        ></video>
      </div>
      <div class="col-12">
        <div class="mt-3 mb-3 text-center">
          <h4 :class="{ 'd-none': !cameraStream }">
            ({{ currentImageIndex + 1 }}/{{ imageInputs.length }})
            {{ currentImage.title }}
            <span
              class="badge badge-primary"
              v-if="currentImage.required == false"
              >Optional</span
            >
          </h4>
          <p :class="{ 'd-none': !cameraStream }">
            Click capture to take an image and proceed to the next step.
          </p>
        </div>
        <div class="text-center">
          <button
            @click="captureImage()"
            class="btn btn-primary d-inline"
            :disabled="isCapturing || currentImage.file"
            :class="{ 'd-none': !cameraStream }"
            v-if="areInputsEntered == true"
          >
            Capture
          </button>
          <button
            class="d-block text-center btn btn-no-outline mt-3 mb-0 mx-auto btn-sm"
            v-if="currentImage.video != '' || currentImage.image != ''"
            :class="{ 'd-none': !cameraStream }"
            @click="
              $store.commit('openModal', {
                title: currentImage.title,
                step: currentImageIndex + 1,
                description: '',
                steps: [],
                video: currentImage.video == '' ? '' : currentImage.video,
                image: currentImage.image == '' ? '' : currentImage.image,
              })
            "
          >
            View Instructions →
          </button>
        </div>
      </div>
    </div>
    <button
      @click="finishStep"
      v-if="isNextButtonVisible && (!loading || finished)"
      :class="{
        'd-none': cameraStream && !isNextButtonVisible,
        'float-end': allOptionalStepsLeft,
        'mb-3': allOptionalStepsLeft,
      }"
      class="btn btn-primary mt-3"
      :disabled="loading"
    >
      <span v-if="!loading && !allOptionalStepsLeft">Next</span>
      <span v-if="!loading && allOptionalStepsLeft">Skip</span>
      <span v-if="loading"
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "CameraStep",
  props: {
    imageInputs: {
      type: Array,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentImageIndex: 0, // Index to track the current image input
      cameraStream: null, // Store the camera stream
      isCapturing: false, // Flag to indicate image capture mode
      finished: false,
      //Specific input logic
      tread_depth: "",
    };
  },
  mounted() {
    // Automatically initialize the camera when the component is mounted
    this.initializeCamera();
  },
  methods: {
    async initializeCamera() {
      try {
        // Check if camera access is already granted
        if (!this.cameraStream) {
          this.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: "environment", // Request the back camera
            },
          });
          this.$refs.cameraFeed.srcObject = this.cameraStream; // Bind camera stream to video element
          this.$refs.cameraFeed.play(); // Start playing the camera feed
        }
      } catch (error) {
        console.error("Error initializing camera:", error);
        alert("Failed to access the camera. Please check your permissions.");
      }
    },

    async captureImage() {
      this.isCapturing = true;

      // Ensure the camera is initialized before capturing an image
      await this.initializeCamera(); // Check for camera access and initialize

      // Capture a frame from the camera stream
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.cameraFeed.videoWidth;
      canvas.height = this.$refs.cameraFeed.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(this.$refs.cameraFeed, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/jpeg");

      // Convert the data URL to a Blob
      const blobData = this.dataURLtoBlob(imageDataURL);

      // Create a File from the Blob
      const file = new File([blobData], "captured_image.jpg", {
        type: "image/jpeg",
      });

      // Update the current image input with the captured image
      this.currentImage.file = file;

      // Call the uploadImage method with the captured image data
      this.uploadImage(file, this.currentImage);
    },

    dataURLtoBlob(dataURL) {
      const parts = dataURL.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },

    async uploadImage(imageData, updatedImageInput) {
      this.loading = true;

      // Merge the existing JSON data with data from updatedImageInput
      const jsonData = {
        step: {
          title: this.$route.meta.title,
          number: this.$route.meta.step,
          category: this.$route.meta.category,
        },
        user: { first_name: "", last_name: "", user_id: "" },
        vehicle: {
          vin: this.$store.state.user.reservations.find((r) => {
            return (
              r.platform_id == this.$route.params.reservation_id.toUpperCase()
            );
          })
            ? this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              }).vehicle_vin
            : null,
        },
        reservation: {
          platform_id: this.$route.params.reservation_id.toUpperCase(),
        },
        meta: { ...updatedImageInput }, // Merge updatedImageInput data into jsonData
        inputs: {
          tread_depth: null,
        },
      };
      jsonData.meta.title =
        updatedImageInput.category == "Damage"
          ? "Damage-" + (parseInt(this.currentImageIndex) + 1).toString()
          : updatedImageInput.title;

      //Modify json with special inputs
      if (this.currentImage.inputs.includes("tread_depth")) {
        jsonData.inputs.tread_depth = this.tread_depth;
      }

      // Define the params object with auth: true
      const params = { auth: true };

      // Create a FormData object
      const formData = new FormData();
      formData.append("file", imageData);
      formData.append("json_data", JSON.stringify(jsonData));

      try {
        const response = await this.$axios.post(
          this.$store.state.root_url + "/vehicle/upload",
          formData,
          {
            params,
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );

        if (response.status === 200) {
          // Move to the next image input if available
          if (this.currentImageIndex < this.imageInputs.length - 1) {
            this.currentImageIndex++;
          } else {
            this.finished = true;
            this.stopCamera();
          }
        } else {
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        }
      } catch (error) {
        console.error("Error uploading images:", error);
        alert(
          "Failed to upload images. Please try again or contact support if the error persists."
        );
      } finally {
        this.loading = false;
        this.isCapturing = false;
        //Reset Inputs
        this.tread_depth = "";
      }
    },

    async finishStep() {
      this.loading = true;

      // Define the params object with auth: true
      const params = { auth: true };

      // Send the JSON data to the server
      this.$axios
        .post(
          this.$store.state.root_url + "/step/finish",
          {
            step: {
              title: this.$route.meta.title,
              number: this.$route.meta.step.toString(),
              category: this.$route.meta.category,
            },
            user: { first_name: "", last_name: "", user_id: "" },
            vehicle: {
              vin: this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
                ? this.$store.state.user.reservations.find((r) => {
                    return (
                      r.platform_id ==
                      this.$route.params.reservation_id.toUpperCase()
                    );
                  }).vehicle_vin
                : null,
            },
            reservation: {
              platform_id: this.$route.params.reservation_id.toUpperCase(),
            },
          },
          {
            params,
          }
        )
        .then((response) => {
          // Handle the response from the server
          if (response.status === 200) {
            this.loading = false;
            const parentRoute =
              this.$route.matched[this.$route.matched.length - 2];
            if (parentRoute.name === "pickup") {
              this.$router.push(
                "/pickup/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            } else {
              this.$router.push(
                "/return/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            }
          } else {
            this.loading = false;
            alert(
              "Failed to upload images. Please try again or contact support if the error persists."
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error uploading images:", error);
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        });
    },

    stopCamera() {
      // Stop the camera stream
      if (this.cameraStream) {
        this.cameraStream.getTracks()[0].stop(); // Stop the first (and only) track
        this.cameraStream = null; // Reset the camera stream
      }
    },
  },
  computed: {
    currentImage() {
      return this.imageInputs[this.currentImageIndex];
    },
    isNextButtonVisible() {
      // Check if there are any imageInputs with file set to null that are required
      return !this.imageInputs.some(
        (input) => input.required == true && input.file === null
      );
    },
    areInputsEntered() {
      //List each custom input here
      if (
        this.currentImage.inputs.includes("tread_depth") &&
        this.tread_depth == ""
      ) {
        return false;
      }
      return true;
    },
    allOptionalStepsLeft() {
      // Check if the current step is optional, it's not the last step, and all following steps are optional
      const isCurrentStepOptional =
        !this.imageInputs[this.currentImageIndex].required;
      const isNotLastStep =
        this.currentImageIndex < this.imageInputs.length && !this.finished;
      const allFollowingOptional = this.imageInputs
        .slice(this.currentImageIndex + 1) // Now correctly starts checking after the current step
        .every((input) => !input.required);

      return isCurrentStepOptional && isNotLastStep && allFollowingOptional;
    },
  },
  beforeDestroy() {
    // Cleanup resources (stop the camera stream) when the component is destroyed
    this.stopCamera();
  },
};
</script>
