<template>
  <div class="pb-5">
    <p>Please clean the vehicle by following the below steps.</p>
    <div
      :class="{ 'mt-3': index != 0 }"
      v-for="(category, index) in checklist"
      :key="index"
    >
      <h6 class="mt-5">{{ category.title }}</h6>
      <p>{{ category.instruction }}</p>
      <ul>
        <li v-for="task in category.tasks" :key="task">{{ task }}</li>
      </ul>
      <p v-if="category.disclaimer" class="disclaimer">
        {{ category.disclaimer }}
      </p>
    </div>
    <button
      @click="finishStep"
      class="btn btn-primary mt-3"
      :disabled="loading"
    >
      <span v-if="!loading">Next</span>
      <span v-else
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "StepFour",
  data() {
    return {
      loading: false,
      checklist: [
        {
          title: "Glass Cleaning",
          instruction:
            "Use blue spray to lift away dirt and grime off of all glass.",
          tasks: ["Windshields.", "Windows.", "Sunroofs."],
        },
        {
          title: "Vacuuming",
          instruction: "Thoroughly vacuum all areas of the car.",
          tasks: [
            "Seats.",
            "Floor mats.",
            "Crevices between seats.",
            "Door pockets.",
            "Trunk and additional trunk storage.",
            "Area below the screen for phone chargers.",
          ],
        },
        {
          title: "Surface Cleaning",
          instruction: "Use Purple Power spray and a rag to clean surfaces.",
          tasks: [
            "Seats",
            "Center console area, including cup holders, armrest, sliding door, and console sides.",
            "Dashboard",
            "Door jams (the part of the vehicle body visible when the door is open.)",
          ],
          disclaimer:
            "Note: DO NOT spray the screen. Instead, use a damp rag to gently wipe it.",
        },
        {
          title: "Inspection",
          instruction: "",
          tasks: ["Inspect all cleaned areas again for damage."],
        },
      ],
    };
  },
  methods: {
    async finishStep() {
      this.loading = true;

      // Define the params object with auth: true
      const params = { auth: true };

      // Send the JSON data to the server
      this.$axios
        .post(
          this.$store.state.root_url + "/step/finish",
          {
            step: {
              title: this.$route.meta.title,
              number: this.$route.meta.step,
              category: this.$route.meta.category,
            },
            user: { first_name: "", last_name: "", user_id: "" },
            vehicle: {
              vin: this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
                ? this.$store.state.user.reservations.find((r) => {
                    return (
                      r.platform_id ==
                      this.$route.params.reservation_id.toUpperCase()
                    );
                  }).vehicle_vin
                : null,
            },
            reservation: {
              platform_id: this.$route.params.reservation_id.toUpperCase(),
            },
          },
          {
            params,
          }
        )
        .then((response) => {
          // Handle the response from the server
          if (response.status === 200) {
            this.loading = false;
            const parentRoute =
              this.$route.matched[this.$route.matched.length - 2];
            if (parentRoute.name === "pickup") {
              this.$router.push(
                "/pickup/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            } else {
              this.$router.push(
                "/return/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            }
          } else {
            this.loading = false;
            alert(
              "Failed to upload images. Please try again or contact support if the error persists."
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error uploading images:", error);
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        });
    },
  },
};
</script>
