<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="instructionModal"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    ref="instructionModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            <span class="highlighted">View instructions.</span>
          </h1>
          <!--<button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>-->
        </div>
        <div class="modal-body">
          <iframe
            v-if="$store.state.instructions.video != ''"
            width="100%"
            height="315"
            :src="$store.state.instructions.video"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="rounded"
          ></iframe>
          <img
            :src="
              $store.state.instructions.image &&
              $store.state.instructions.video == ''
            "
            style="width: 100%; height: auto"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionModal",
  data() {
    return {
      modalInstance: null, // store the modal instance here
    };
  },
  methods: {
    showModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.instructionModal;
        this.modalInstance = new window.bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },
    hideModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
  },
  watch: {
    "$store.state.isModalOpen": {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.showModal();
        } else {
          this.hideModal();
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      const modalElement = this.$refs.instructionModal;
      modalElement.addEventListener("hidden.bs.modal", () => {
        this.$store.commit("closeModal");
      });
    });
  },
};
</script>
