<template>
  <div>
    <div class="container">
      <div class="row g-0" style="min-height: 65vh !important">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">My Vehicles.</span>
            <!-- Search input for fuzzy search -->
            <input
              type="text"
              class="form-control w-auto ms-auto mt-auto me-2 d-none d-md-block"
              placeholder="Search by VIN, Model, License Plate, or Status"
              v-model="searchQuery"
            />
          </h1>
          <input
            type="text"
            class="form-control w-100 d-md-none mt-3 mb-3"
            placeholder="Search by VIN, Model, or License Plate"
            v-model="searchQuery"
          />
          <p v-if="!$store.state.user.vehicles.length">
            Looks like you don't have access to any vehicles! Contact your
            supervisor to get access.
          </p>
          <!-- Active Reservations -->
          <table
            class="table table-striped"
            v-if="$store.state.user.vehicles.length"
          >
            <thead class="border-none">
              <tr>
                <th scope="col">Vin</th>
                <th scope="col">Model</th>
                <th scope="col">Plate</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="sortedVehicles.length === 0">
                <td colspan="4" style="background-color: #f7f7f7">
                  No results
                </td>
              </tr>
              <tr
                v-for="vehicle of sortedVehicles"
                :key="'vehicle-' + vehicle.id"
              >
                <td>{{ vehicle.vin.slice(-6) }}</td>
                <td scope="row">{{ vehicle.model }}</td>
                <td>{{ vehicle.license_plate }}</td>
                <td>{{ vehicle.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mb-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "VehiclesView",
  data: function () {
    return {
      searchQuery: "",
    };
  },
  computed: {
    sortedVehicles: function () {
      const searchQuery = this.searchQuery.toLowerCase().trim();
      return this.$store.state.user.vehicles
        .filter((vehicle) => {
          // Filter based on VIN, Model, or License Plate
          const vin = vehicle.vin.slice(-6).toLowerCase();
          const model = vehicle.model.toLowerCase();
          const licensePlate = vehicle.license_plate.toLowerCase();
          const status = vehicle.status.toLowerCase();
          return (
            vin.includes(searchQuery) ||
            model.includes(searchQuery) ||
            licensePlate.includes(searchQuery) ||
            status.includes(searchQuery)
          );
        })
        .sort((a, b) => {
          return a.vin.localeCompare(b.vin);
        });
    },
  },
};
</script>
