<template>
  <div>
    <div class="container">
      <div class="row g-0 d-flex" style="min-height: 65vh !important">
        <div class="my-auto">
          <div class="col-12">
            <h1 class="display-5 mt-5 mb-3 text-center">
              <span class="highlighted">Continue to pickup photos?</span>
            </h1>
            <p class="mt-3 mb-3 text-center">
              This is an automated suggestion to do the pickup process for
              {{ reservation.customer_first_name }}'s upcoming reservation in
              this vehicle. It starts in {{ timeUntilPickup }}.
            </p>
            <router-link
              :to="'/pickup/' + reservation.platform_id.toLowerCase() + '/0'"
              tag="button"
              class="btn btn-primary mt-3 mb-3 text-center mx-auto d-block"
            >
              Start
            </router-link>
            <router-link
              to="/schedule"
              tag="a"
              class="d-block mx-auto text-center mt-5"
            >
              ← Back to schedule</router-link
            >
          </div>
          <div class="col-12 mb-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NextView",
  computed: {
    reservation: function () {
      return this.$store.state.user.reservations.find((reservation) => {
        return (
          reservation.platform_id ==
          this.$route.params.reservation_id.toUpperCase()
        );
      });
    },
    timeUntilPickup: function () {
      if (
        !this.reservation ||
        !this.reservation.pickup_at ||
        !this.reservation.pickup_timezone
      ) {
        return "less than 24 hours";
      }

      // Parse the pickup time in the reservation's timezone
      const pickupTime = new Date(this.reservation.pickup_at);
      const timezone = this.reservation.pickup_timezone;

      // Format both current time and pickup time in the specified timezone
      const options = {
        timeZone: timezone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const formattedPickupTime = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(pickupTime);
      const formattedCurrentTime = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(new Date());

      // Calculate the difference in milliseconds
      const diffMs =
        new Date(formattedPickupTime) - new Date(formattedCurrentTime);

      // Convert milliseconds to hours, minutes
      const diffHrs = Math.floor(diffMs / 1000 / 60 / 60);
      const diffMins = Math.floor((diffMs / 1000 / 60) % 60);

      return `${diffHrs} hours and ${diffMins} minutes`;
    },
  },
};
</script>
