import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import HomeView from "../views/HomeView.vue";
import VehiclesView from "../views/VehiclesView.vue";
import ScheduleView from "../views/ScheduleView.vue";

import ReturnView from "../views/ReturnView.vue";
import PickupView from "../views/PickupView.vue";
import StepZero from "@/views/vehicle/StepZero.vue";
import StepOne from "@/views/vehicle/StepOne.vue";
import StepTwo from "@/views/vehicle/StepTwo.vue";
import StepThree from "@/views/vehicle/StepThree.vue";
import StepFour from "@/views/vehicle/StepFour.vue";
import StepFive from "@/views/vehicle/StepFive.vue";
import StepSix from "@/views/vehicle/StepSix.vue";
import StepSeven from "@/views/vehicle/StepSeven.vue";
import StepEight from "@/views/vehicle/StepEight.vue";

import NextView from "../views/NextView.vue";

import LoginView from "../views/LoginView.vue";
import EmailSentView from "../views/EmailSentView.vue";
import RedirectView from "../views/RedirectView.vue";
import AuthenticateView from "../views/AuthenticateView.vue";

import ErrorView from "../views/ErrorView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authenticated: true,
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: VehiclesView,
    meta: {
      title: "Vehicles",
      authenticated: true,
    },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: ScheduleView,
    meta: {
      title: "My Schedule",
      authenticated: true,
    },
  },
  {
    path: "/next/:reservation_id",
    name: "next",
    component: NextView,
    meta: {
      title: "Auto Suggestion",
      authenticated: true,
    },
  },
  {
    path: "/return/:reservation_id",
    name: "return",
    component: ReturnView,
    meta: {
      title: "Return Process",
      authenticated: true,
    },
    children: [
      {
        path: "0",
        component: StepZero,
        meta: {
          title: "Start Return",
          authenticated: true,
          step: 0,
          category: "Return",
        },
      },
      {
        path: "1",
        component: StepOne,
        meta: {
          title: "Step 1: Return (Exterior Images)",
          authenticated: true,
          step: 1,
          category: "Return",
        },
      },
      {
        path: "2",
        component: StepTwo,
        meta: {
          title: "Step 2: Return (Interior Images)",
          authenticated: false,
          step: 2,
          category: "Return",
        },
      },
      {
        path: "3",
        component: StepThree,
        meta: {
          title: "Step 3: Inspection",
          authenticated: true,
          step: 3,
          category: "Return",
        },
      },
      {
        path: "4",
        component: StepEight,
        meta: {
          title: "Step 4: Damage",
          authenticated: true,
          step: 4,
          category: "Return",
        },
      },
      {
        path: "5",
        component: StepFour,
        meta: {
          title: "Step 5: Cleaning Checklist",
          authenticated: true,
          step: 5,
          category: "Return",
        },
      },
      {
        path: "6",
        component: StepSeven,
        meta: {
          title: "Step 6: Finish",
          authenticated: true,
          step: 6,
          category: "Return",
        },
      },
    ],
  },
  {
    path: "/pickup/:reservation_id",
    name: "pickup",
    component: PickupView,
    meta: {
      title: "Pickup Process",
      authenticated: true,
    },
    children: [
      {
        path: "0",
        component: StepZero,
        meta: {
          title: "Start Pickup",
          authenticated: true,
          step: 0,
          category: "Pickup",
        },
      },
      {
        path: "1",
        component: StepFive,
        meta: {
          title: "Step 1: Pickup (Exterior Images)",
          authenticated: true,
          step: 1,
          category: "Pickup",
        },
      },
      {
        path: "2",
        component: StepSix,
        meta: {
          title: "Step 2: Pickup (Interior Images)",
          authenticated: true,
          step: 2,
          category: "Pickup",
        },
      },
      {
        path: "3",
        component: StepSeven,
        meta: {
          title: "Step 3: Finish",
          authenticated: true,
          step: 3,
          category: "Pickup",
        },
      },
    ],
  },

  {
    path: "/redirect/auth/:token",
    name: "redirect",
    component: RedirectView,
    meta: {
      title: "Redirecting...",
      authenticated: false,
    },
  },
  {
    path: "/get-started",
    name: "login",
    component: LoginView,
    meta: {
      title: "Get Started",
      authenticated: false,
    },
  },
  {
    path: "/get-started/confirmation",
    name: "emailSent",
    component: EmailSentView,
    meta: {
      title: "Check Your Email",
      authenticated: false,
    },
  },
  {
    path: "/get-started/authenticate",
    name: "authenticate",
    component: AuthenticateView,
    meta: {
      title: "Authenticating",
      authenticated: false,
    },
  },
  {
    path: "/error/:code",
    name: "error",
    component: ErrorView,
    meta: {
      title: "Page Error",
      authenticated: false,
    },
  },
  {
    path: "*",
    name: "404",
    component: PageNotFoundView,
    meta: {
      title: "Page Not Found",
      authenticated: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  if (to.meta.authenticated == true) {
    if (store.state.authenticated == false) {
      next({ path: "/get-started" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + " | Fleet Management | Resla";
  });
});

export default router;
