<template>
  <div class="pb-5">
    <p>Please answer the below questions. Cleaning begins next step.</p>

    <!-- Damage -->
    <div class="mb-3">
      <h6 class="mt-5">Damage</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Open claim-->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="damage"
              v-model="damage"
            />
            <label class="form-check-label" for="damage">
              There is damage to the vehicle.
            </label>
          </div>
          <div v-if="damage">
            <div class="mb-3 mt-3">
              <label for="damageNotes" class="form-label"
                >Please provide more detail:</label
              >
              <textarea
                class="form-control"
                id="damageNotes"
                v-model="damageNotes"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Repairable and Replaceable -->
    <div class="mt-3 mb-3">
      <h6 class="mt-5">Smoking</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="smokingInCar"
              v-model="smokingInCar"
            />
            <label class="form-check-label" for="smokingInCar">
              There is smoke damage.
            </label>
          </div>
          <!-- Smoke Treatment Section (if smokingInCar is checked) -->
          <div v-if="smokingInCar">
            <div class="form-check form-check-lg mt-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="smokeTreatment"
                v-model="smokeTreatment"
              />
              <label class="form-check-label" for="smokeTreatment">
                Requires a smoke treatment.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3">
      <h6 class="mt-5">Vehicle Access</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Lost key -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="key"
              v-model="key"
            />
            <label class="form-check-label" for="key">
              The physical car key is missing.
            </label>
          </div>
        </div>
      </div>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Deleted Keys -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="deletedKeys"
              v-model="deletedKeys"
              :required="true"
            />
            <label class="form-check-label" for="deletedKeys">
              There are no Charge Keys and no cell phone keys listed in the
              "Locks" section of the vehicle.
              <span class="badge badge-primary ms-1">Required</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3">
      <h6 class="mt-5">Cleaning</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Extensive Cleaning -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="extensive_cleaning"
              v-model="extensiveCleaning"
            />
            <label class="form-check-label" for="extensive_cleaning">
              The vehicle required extensive cleaning.
            </label>
          </div>
        </div>
      </div>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Missing Wash Sticker -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="wash_sticker"
              v-model="washSticker"
            />
            <label class="form-check-label" for="wash_sticker">
              The vehicle is missing the wash sticker.
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3">
      <h6 class="mt-5">Timeliness</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Lost key -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="late_fee"
              v-model="lateFee"
            />
            <label class="form-check-label" for="late_fee">
              The vehicle was returned late.
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3">
      <h6 class="mt-5">Charging</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- j1772 adapter -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="j1772"
              v-model="chargingApapter"
            />
            <label class="form-check-label" for="J1772">
              The J1772 Charging Apapter is missing.
            </label>
          </div>
        </div>
      </div>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Low charge -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="low_battery"
              v-model="lowBattery"
            />
            <label class="form-check-label" for="low_battery">
              The vehicle was returned with less than 80% charge.
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3">
      <h6 class="mt-5">Department of Motor Vehicles</h6>
      <div class="card shadow rounded-3 mt-3 mb-3">
        <div class="card-body">
          <!-- Missing License Plate -->
          <div class="form-check form-check-lg">
            <input
              class="form-check-input"
              type="checkbox"
              id="late_fee"
              v-model="licensePlate"
            />
            <label class="form-check-label" for="late_fee">
              The license plate is missing.
            </label>
          </div>
        </div>
      </div>
    </div>

    <button
      @click="finishStep"
      v-if="isNextButtonVisible"
      class="btn btn-primary mt-3"
      :disabled="loading"
    >
      <span v-if="!loading">Next</span>
      <span v-else
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "StepThree",
  data() {
    return {
      loading: false,
      smokingInCar: false,
      smokeTreatment: false,
      key: false,
      chargingApapter: false,
      imageInputs: [],
      damage: false,
      damageNotes: "",
      lowBattery: false,
      extensiveCleaning: false,
      lateFee: false,
      washSticker: false,
      licensePlate: false,
      deletedKeys: false,
    };
  },
  methods: {
    async finishStep() {
      this.loading = true;

      // Define the params object with auth: true
      const params = { auth: true };

      // Send the JSON data to the server
      this.$axios
        .post(
          this.$store.state.root_url + "/step/finish",
          {
            step: {
              title: this.$route.meta.title,
              number: this.$route.meta.step,
              category: this.$route.meta.category,
            },
            user: { first_name: "", last_name: "", user_id: "" },
            vehicle: {
              vin: this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
                ? this.$store.state.user.reservations.find((r) => {
                    return (
                      r.platform_id ==
                      this.$route.params.reservation_id.toUpperCase()
                    );
                  }).vehicle_vin
                : null,
            },
            reservation: {
              platform_id: this.$route.params.reservation_id.toUpperCase(),
            },
            smoking: {
              smokingInCar: this.smokingInCar,
              smokeTreatment: this.smokeTreatment,
            },
            charging: {
              chargingApapter: this.chargingApapter,
              lowBattery: this.lowBattery,
            },
            damage: {
              claim: this.damage,
              notes: this.damageNotes,
            },
            cleaning: {
              extensiveCleaning: this.extensiveCleaning,
              washSticker: this.washSticker,
            },
            key: {
              missing: this.key,
            },
            return: {
              lateFee: this.lateFee,
            },
            plate: {
              licensePlate: this.licensePlate,
            },
          },
          {
            params,
          }
        )
        .then((response) => {
          // Handle the response from the server
          if (response.status === 200) {
            this.loading = false;
            const parentRoute =
              this.$route.matched[this.$route.matched.length - 2];
            if (parentRoute.name === "pickup") {
              this.$router.push(
                "/pickup/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            } else {
              this.$router.push(
                "/return/" +
                  this.$route.params.reservation_id +
                  "/" +
                  (parseInt(this.$route.meta.step) + 1).toString()
              );
            }
          } else {
            this.loading = false;
            alert(
              "Failed to upload images. Please try again or contact support if the error persists."
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error uploading images:", error);
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        });
    },
  },
  computed: {
    isNextButtonVisible() {
      return this.deletedKeys == true;
    },
  },
};
</script>
