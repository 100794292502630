var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row g-0",staticStyle:{"min-height":"65vh !important"}},[_c('div',{staticClass:"col-12"},[_vm._m(0),(!_vm.$store.state.user.reservations.length)?_c('p',[_vm._v(" Looks like you don't have access to any reservations! Contact your supervisor to get access. ")]):_vm._e(),(_vm.unassignedReservations.length > 0)?_c('p',[_vm._v(" Uh, oh. There "),(_vm.unassignedReservations.length > 1)?_c('span',[_vm._v("are "+_vm._s(_vm.unassignedReservations.length)+" reservations")]):_c('span',[_vm._v("is "+_vm._s(_vm.unassignedReservations.length)+" reservation")]),_vm._v(" that "),(_vm.unassignedReservations.length > 1)?_c('span',[_vm._v("do")]):_c('span',[_vm._v("does")]),_vm._v(" not have cars assigned to it. Please contact your fleet supervisor, who is in charge of updating the dispatch schedule. ")]):_vm._e(),_c('div',{staticClass:"table-responsive mt-3"},[_c('table',{staticClass:"table mb-3 table-striped"},[_c('thead',{staticClass:"border-none"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scolpe":"col"}},[_vm._v("Type")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Vin")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cleaning")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Location")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Time")]),(_vm.isMasterSchedule == true)?_c('th',{attrs:{"scope":"col"}},[_vm._v("Assigned")]):_vm._e(),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action")])])]),_c('tbody',[(_vm._.isEmpty(_vm.groupedReservations))?_c('tr',[_c('td',{staticStyle:{"background-color":"#f7f7f7"},attrs:{"colspan":"9"}},[_vm._v(" No items have been added to your schedule. ")])]):_vm._e()]),_c('tbody',_vm._l((_vm.filteredReservations),function(reservation){return _c('tr',{key:'reservation-cleaning-' +
                  reservation.platform_id +
                  '-' +
                  reservation.type,staticClass:"mt-3"},[_c('td',{staticClass:"align-middle"},[(reservation.order != 1000)?_c('span',{staticClass:"badge badge-tertiary"},[_vm._v(_vm._s(reservation.order))]):_c('span')]),_c('td',{staticClass:"align-middle d-none"},[_vm._v(" "+_vm._s(reservation.platform_id)+" ")]),_c('td',{staticClass:"align-middle"},[_c('span',{staticClass:"badge badge-tertiary"},[_vm._v(_vm._s(reservation.type[0].toUpperCase()))])]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(reservation.vehicle_vin.slice(-6))+" ")]),_c('td',{staticClass:"align-middle"},[(reservation.action === true)?_c('span',{staticClass:"badge badge-tertiary"},[_vm._v("Complete")]):_vm._e(),(reservation.action === false)?_c('span',{staticClass:"badge badge-primary"},[_vm._v("Incomplete")]):_vm._e()]),_c('td',{staticClass:"align-middle",staticStyle:{"white-space":"nowrap !important"}},[(reservation.status == 'Pending')?_c('span',[_vm._v("Pending")]):_vm._e(),(
                      reservation.status == 'On Rent' &&
                      reservation.late == false
                    )?_c('span',[_vm._v("On Rent")]):_vm._e(),(
                      reservation.status == 'On Rent' &&
                      reservation.late == true
                    )?_c('span',[_vm._v("On Rent (Late)")]):_vm._e(),(reservation.status == 'Canceled')?_c('span',[_vm._v("Canceled")]):_vm._e(),(
                      reservation.status == 'Returned' &&
                      reservation.late == false
                    )?_c('span',[_vm._v("Returned")]):_vm._e(),(
                      reservation.status == 'Returned' &&
                      reservation.late == true
                    )?_c('span',[_vm._v("Returned (Late)")]):_vm._e()]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$store.state.user.locations.find(function (location) { return reservation.location_id == location.hq_id; }) ? _vm.$store.state.user.locations.find(function (location) { return reservation.location_id == location.hq_id; }).name : "")+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(new Date(reservation.date).toLocaleDateString("en-US", { timeZone: reservation.timezone, hour: "2-digit", minute: "2-digit", hour12: true, }))+" ")]),(_vm.isMasterSchedule == true)?_c('td',{staticClass:"align-middle",staticStyle:{"min-width":"25%"}},[_vm._v(" "+_vm._s(_vm.$store.state.user.employees.find(function (employee) { return employee.user_id == reservation.user_id; }) ? _vm.$store.state.user.employees.find(function (employee) { return employee.user_id == reservation.user_id; }).name.first_name + " " + _vm.$store.state.user.employees.find(function (employee) { return employee.user_id == reservation.user_id; }).name.last_name : "")+" ")]):_vm._e(),_c('td',{staticClass:"align-middle"},[(reservation.action === false)?_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":'/' +
                      reservation.type +
                      '/' +
                      reservation.platform_id.toLowerCase() +
                      '/0',"tag":"button"}},[_vm._v(" Go ")]):_vm._e()],1)])}),0)])])]),_c('div',{staticClass:"col-12 mb-5"})])]),_c('div',{staticClass:"modal fade",attrs:{"id":"schedule-filters","tabindex":"-1","aria-labelledby":"filterModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(1),_c('div',{staticClass:"modal-body pt-0"},[_c('label',[_vm._v("Date:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"form-select mb-3",attrs:{"id":"dateSelect"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectDate]}},[_c('option',{attrs:{"value":"yesterday"}},[_vm._v("Yesterday")]),_c('option',{attrs:{"value":"today","selected":""}},[_vm._v("Today")]),_c('option',{attrs:{"value":"tomorrow"}},[_vm._v("Tomorrow")])]),_c('label',{staticClass:"mt-3"},[_vm._v("Schedule Type:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.isMasterSchedule),expression:"isMasterSchedule"}],staticClass:"form-select mb-3",attrs:{"id":"changeSelect"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.isMasterSchedule=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":""},domProps:{"value":false}},[_vm._v("My Schedule")]),_c('option',{domProps:{"value":true}},[_vm._v("Master Schedule")])])]),_vm._m(2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"highlighted me-auto"},[_vm._v("My Schedule.")]),_c('button',{staticClass:"btn btn-primary me-2 mt-auto",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#schedule-filters"}},[_vm._v(" Filters ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h1',[_c('span',{staticClass:"highlighted"},[_vm._v("Filters.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" Close ")])])}]

export { render, staticRenderFns }