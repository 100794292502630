<template>
  <div class="pb-5">
    <p>
      Welcome to the
      <span v-if="this.$route.meta.category == 'Pickup'">pickup </span>
      <span v-if="this.$route.meta.category == 'Return'">return </span>
      process. The following steps will walk you through the
      <span v-if="this.$route.meta.category == 'Return'">cleaning and </span>
      imaging process. Click start to begin.
    </p>
    <button
      @click="nextStep()"
      class="btn btn-primary mt-3 float-end"
      :disabled="loading"
    >
      <span v-if="!loading">Start</span>
      <span v-else
        ><div
          class="spinner-border"
          style="height: 1rem !important; width: 1rem !important"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div></span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "StepZero",
  data() {
    return {
      imageInputs: [],
      loading: false,
    };
  },
  methods: {
    async nextStep() {
      this.loading = true;
      // Filter out image inputs without files

      if (
        this.imageInputs.some((input) => input.required && input.file === null)
      ) {
        alert("No images selected.");
        return;
      }

      // Define the params object with auth: true
      const params = { auth: true };

      // Send the JSON data to the server
      this.$axios
        .post(
          this.$store.state.root_url + "/step/finish",
          {
            step: {
              title: this.$route.meta.title,
              number: 0,
              category: this.$route.meta.category,
            },
            user: { first_name: "", last_name: "", user_id: "" },
            vehicle: {
              vin: this.$store.state.user.reservations.find((r) => {
                return (
                  r.platform_id ==
                  this.$route.params.reservation_id.toUpperCase()
                );
              })
                ? this.$store.state.user.reservations.find((r) => {
                    return (
                      r.platform_id ==
                      this.$route.params.reservation_id.toUpperCase()
                    );
                  }).vehicle_vin
                : null,
            },
            reservation: {
              platform_id: this.$route.params.reservation_id.toUpperCase(),
            },
          },
          {
            params,
          }
        )
        .then((response) => {
          // Handle the response from the server
          if (response.status === 200) {
            this.loading = false;
            const parentRoute =
              this.$route.matched[this.$route.matched.length - 2];
            if (parentRoute.name === "pickup") {
              this.$router.push(
                "/pickup/" + this.$route.params.reservation_id + "/1"
              );
            } else {
              this.$router.push(
                "/return/" + this.$route.params.reservation_id + "/1"
              );
            }
          } else {
            this.loading = false;
            alert(
              "Failed to upload images. Please try again or contact support if the error persists."
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error uploading images:", error);
          alert(
            "Failed to upload images. Please try again or contact support if the error persists."
          );
        });
    },
  },
  computed: {
    isNextButtonVisible() {
      // Check if there are any imageInputs with file set to null
      return !this.imageInputs.some(
        (input) => input.required && input.file === null
      );
    },
    groupedImages: function () {
      return this._.groupBy(this.imageInputs, "category");
    },
  },
};
</script>
